import axios from "axios";

export const getAllItems = async (sent, page = 1) => {
  try {
    const { data } = await axios.get(
      `https://${window.location.hostname}/api/lead/all?sent=${sent}&page=${page}`
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const sendLeads = async (items) => {
  try {
    await axios.post(
      `https://${window.location.hostname}/api/lead/send`,
      items
    );
  } catch (error) {
    console.log(error);
  }
};
