import React, { useEffect, useState } from "react";
// @ts-ignore
import checkbox from "../../assets/images/checkbox.png";
// @ts-ignore
import notCheckbox from "../../assets/images/notcheckbox.png";
import { getAllItems, sendLeads } from "../../services/axios.ts";
import { toast } from "react-toastify";

export const Table = () => {
  const [checked, setChecked] = useState<any>([]);
  const [selected, setSelected] = useState<any>([]);
  const [items, setItems] = useState<any>([]);
  const [page, setPage] = useState(1);
  const notify = () => toast.success("Відправили!");
  useEffect(() => {
    const fetchItems = async () => {
      const data = (await getAllItems(false, page)) ?? [];

      setItems((prev: any) => {
        return [...prev, ...data];
      });

      setChecked((prev: any) => {
        const arr: any = [];
        for (const item of data) {
          arr.push(false);
        }
        return arr;
      });
    };

    fetchItems();
  }, [page]);

  const onSendButtonClick = async () => {
    await sendLeads(selected);
  };

  return (
    <>
      <div className="table__container">
        <div className="table__header">
          <div className="table__header--item">Номер</div>
          <div className="table__header--item">Прізвище</div>
          <div className="table__header--item">Email</div>
          <div className="table__header--item">Відправка</div>
        </div>
        <div className="table__body">
          {items?.map(({ _id, requestBody, requestUrl }, index) => (
            <div
              className="table__body--container"
              key={_id}
              style={{ background: checked[index] ? "#1C5680" : "" }}
            >
              <div className="table__body--item">{requestBody.phone}</div>
              <div className="table__body--item">{requestBody.lastname}</div>
              <div className="table__body--item">{requestBody.email}</div>
              {/* <div className="table__body--item">{requestBody.partner}</div> */}
              <div className="table__body--item">
                {checked[index] ? (
                  <img
                    style={{ cursor: "pointer" }}
                    src={checkbox}
                    alt="checkbox"
                    className="table__body--checkbox"
                    onClick={() => {
                      setChecked((prev) => {
                        const arr = [...prev];
                        arr[index] = !arr[index];
                        return arr;
                      });
                      setSelected((prev) => {
                        const arr = [...prev];
                        arr.splice(index, 1);
                        return arr;
                      });
                    }}
                  />
                ) : (
                  <img
                    style={{ cursor: "pointer" }}
                    src={notCheckbox}
                    alt="Not checkbox"
                    className="table__body--checkbox"
                    onClick={() => {
                      setChecked((prev) => {
                        const arr = [...prev];
                        arr[index] = !arr[index];
                        return arr;
                      });
                      setSelected((prev) => {
                        const arr = [...prev];
                        arr.push(items[index]);
                        return arr;
                      });
                    }}
                  />
                )}
              </div>
            </div>
          ))}
        </div>
        <div className="table__button--container">
          <button
            className="button__loadmore"
            type="button"
            onClick={() => {
              setPage((prev) => (prev += 1));
            }}
            style={{ width: "325px" }}
          >
            <svg
              width="22"
              height="22"
              viewBox="0 0 22 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11 4.5V1.25M15.6042 6.39583L17.9333 4.06667M17.5 11H20.75M15.6042 15.6042L17.9333 17.9333M11 17.5V20.75M6.39583 15.6042L4.06667 17.9333M4.5 11H1.25M6.39583 6.39583L4.06667 4.06667"
                stroke="white"
                strokeWidth="2.16667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            Показати більше
          </button>
          <button
            className="button__send"
            type="button"
            style={{ width: "100%" }}
            onClick={() => {
              onSendButtonClick();
              notify();
            }}
          >
            <svg
              width="23"
              height="22"
              viewBox="0 0 23 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M22.3335 11C22.3335 16.1068 22.3335 18.6603 20.7475 20.2473C19.1593 21.8333 16.607 21.8333 11.5002 21.8333C6.39333 21.8333 3.83991 21.8333 2.25391 20.2473C0.666832 18.6592 0.666832 16.1068 0.666833 11C0.666833 5.89317 0.666834 3.33975 2.25392 1.75375C3.83883 0.16667 6.39333 0.16667 11.5002 0.166671C16.607 0.166671 19.1604 0.166671 20.7475 1.75375C22.3335 3.33867 22.3335 5.89317 22.3335 11ZM5.271 11C5.271 10.7845 5.3566 10.5779 5.50897 10.4255C5.66135 10.2731 5.86801 10.1875 6.0835 10.1875L11.706 10.1875L9.84266 8.32417C9.76284 8.24979 9.69881 8.16008 9.6544 8.06042C9.60999 7.96075 9.58612 7.85316 9.58419 7.74407C9.58227 7.63497 9.60233 7.52661 9.6432 7.42544C9.68406 7.32427 9.74489 7.23237 9.82204 7.15521C9.89919 7.07806 9.9911 7.01723 10.0923 6.97637C10.1934 6.9355 10.3018 6.91544 10.4109 6.91736C10.52 6.91929 10.6276 6.94316 10.7272 6.98757C10.8269 7.03198 10.9166 7.09601 10.991 7.17584L14.241 10.4258C14.3932 10.5782 14.4786 10.7847 14.4786 11C14.4786 11.2153 14.3932 11.4218 14.241 11.5742L10.991 14.8242C10.9166 14.904 10.8269 14.968 10.7272 15.0124C10.6276 15.0568 10.52 15.0807 10.4109 15.0826C10.3018 15.0846 10.1934 15.0645 10.0923 15.0236C9.9911 14.9828 9.89919 14.9219 9.82204 14.8448C9.74489 14.7676 9.68406 14.6757 9.6432 14.5746C9.60233 14.4734 9.58226 14.365 9.58419 14.2559C9.58611 14.1468 9.60999 14.0393 9.6544 13.9396C9.69881 13.8399 9.76284 13.7502 9.84266 13.6758L11.706 11.8125L6.0835 11.8125C5.86801 11.8125 5.66135 11.7269 5.50897 11.5745C5.3566 11.4222 5.271 11.2155 5.271 11ZM16.1043 15.3333C16.1043 15.5488 16.1899 15.7555 16.3423 15.9079C16.4947 16.0602 16.7013 16.1458 16.9168 16.1458C17.1323 16.1458 17.339 16.0602 17.4914 15.9079C17.6437 15.7555 17.7293 15.5488 17.7293 15.3333L17.7293 6.66667C17.7293 6.45118 17.6437 6.24452 17.4914 6.09215C17.339 5.93977 17.1323 5.85417 16.9168 5.85417C16.7013 5.85417 16.4947 5.93977 16.3423 6.09215C16.1899 6.24452 16.1043 6.45118 16.1043 6.66667L16.1043 15.3333Z"
                fill="white"
              />
            </svg>
            Відправити
          </button>
        </div>
      </div>
    </>
  );
};
