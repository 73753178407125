import React from "react";
import { Route, Routes } from "react-router-dom";
import { Table } from "./components/Table/Table.tsx";
import { Header } from "./components/Header/Header.tsx";
import { Download } from "./components/Download/Download.tsx";
import { TableSent } from "./components/TableSent/TableSent.tsx";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Header />}>
          <Route index element={<Table />} />
          <Route path="sent" element={<TableSent />} />
          <Route path="download" element={<Download />} />
        </Route>
      </Routes>
      <ToastContainer />
    </>
  );
}

export default App;
