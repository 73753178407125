import React, { Suspense } from "react";
import { NavLink, Outlet } from "react-router-dom";

export const Header = () => {
  return (
    <>
      <header className="header">
        <ul className="header__list">
          <li className="header__item">
            <NavLink to="/" className="header__item--link">
              Lead
            </NavLink>
          </li>
          <li className="header__item">
            <NavLink to="/sent" className="header__item--link">
              Sent
            </NavLink>
          </li>
          <li className="header__item">
            <NavLink to="/download" className="header__item--link">
              Download
            </NavLink>
          </li>
        </ul>
      </header>
      <Suspense fallback={<div>Loading...</div>}>
        <Outlet />
      </Suspense>
    </>
  );
};
