import React, { useEffect, useState } from "react";
import { getAllItems } from "../../services/axios.ts";

export const TableSent = () => {
  const [items, setItems] = useState<any>([]);
  const [page, setPage] = useState(1);

  useEffect(() => {
    const fetchItems = async () => {
      const data = (await getAllItems(true, page)) ?? [];

      setItems((prev: any) => {
        return [...prev, ...data];
      });
    };

    fetchItems();
  }, []);

  return (
    <>
      <div className="table__container">
        <div className="table__header">
          <div className="table__header--item">Номер</div>
          <div className="table__header--item">Прізвище</div>
          <div className="table__header--item">Email</div>
          {/* <div className="table__header--item">Партнер</div> */}
        </div>
        <div className="table__body">
          {items?.map(({ _id, requestBody, requestUrl }, index) => (
            <div className="table__body--container" key={_id}>
              <div className="table__body--item">{requestBody.phone}</div>
              <div className="table__body--item">{requestBody.lastname}</div>
              <div className="table__body--item">{requestBody.email}</div>
              {/* <div className="table__body--item">{requestBody.partner}</div> */}
            </div>
          ))}
        </div>
        <div className="table__button--container">
          <button
            className="button__loadmore"
            type="button"
            style={{ width: "100%" }}
            onClick={() => {
              setPage((prev) => (prev += 1));
            }}
          >
            <svg
              width="22"
              height="22"
              viewBox="0 0 22 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11 4.5V1.25M15.6042 6.39583L17.9333 4.06667M17.5 11H20.75M15.6042 15.6042L17.9333 17.9333M11 17.5V20.75M6.39583 15.6042L4.06667 17.9333M4.5 11H1.25M6.39583 6.39583L4.06667 4.06667"
                stroke="white"
                strokeWidth="2.16667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            Показати більше
          </button>
        </div>
      </div>
    </>
  );
};
